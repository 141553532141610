import { currentPage, initNewSection } from '../common/layout';
import { banners, commentsCountUpdate, hitPageView } from '../common/utils/fn';
import { getLoadMoreContent } from '../common/utils/load-more-btn-new.-loader';
import { getMakeModelFilter } from '../common/make-model-filter-loader';
import '../common/jquery-snap-scroll';
import { getM1FindVehicleEu, getM1FindVehicleUs } from '../common/shop/m1-find-vehicle-widget-loader';
import { getCoralCommentsCountUpdate } from '../common/utils/coral-requests-loader';


currentPage.pageInit = ($page, pageData, pageId) => {

  $('.msnt-items-slider').snapScrollControl();
  
  let loadMoreButton = document.querySelector('.m1-pager--next .m1-button');
  loadMoreButton?.addEventListener('click', (event) => {
    event.preventDefault();
    getLoadMoreContent().then((api) => {
      api.loadMoreButtonNew(loadMoreButton);
    });
  });

  getMakeModelFilter().then((api) => {
    api.makeModelFilter();
  });
  getM1FindVehicleEu().then((api) => {
    api.m1FindVehicleEu();
  });
  getM1FindVehicleUs().then((api) => {
    api.m1FindVehicleUs();
  });

  getCoralCommentsCountUpdate().then((api) => {
    api.coralCommentsCountUpdate();
  });
  commentsCountUpdate();
};

currentPage.pageLoadMore = ($html, $btnBox) => {
  $btnBox?.replaceWith($html);
 
  // hybridLazyLoad();
  getM1FindVehicleEu().then((api) => {
    api.m1FindVehicleEu();
  });
  getM1FindVehicleUs().then((api) => {
    api.m1FindVehicleUs();
  });
  initNewSection($html);
  banners($html);
  commentsCountUpdate(true);
  hitPageView(document.location.pathname);
  // bannerLazyload();
  getCoralCommentsCountUpdate().then((api) => {
    api.coralCommentsCountUpdate();
  });

  let loadMoreButton = document.querySelector('.m1-pager--next .m1-button');
  loadMoreButton?.addEventListener('click', (event) => {
    event.preventDefault();
    getLoadMoreContent().then((api) => {
      api.loadMoreButtonNew(loadMoreButton);
    });
  });
};
